import { sortByKey } from 'utils';

import type { I18n } from '@lingui/core';
import type { SelectOptions } from 'types';

export const COUNTRIES_OPTIONS = (i18n: I18n): SelectOptions => [
    {
        value: 'CA',
        label: i18n._('country.CA'),
    },
    {
        value: 'US',
        label: i18n._('country.US'),
    },
];

export const ALL_COUNTRIES_OPTIONS = (i18n: I18n): SelectOptions => [
    {
        value: 'CA',
        label: i18n._('country.CA'),
    },
    {
        value: 'US',
        label: i18n._('country.US'),
    },
    {
        value: 'AF',
        label: i18n._('country.AF'),
    },
    {
        value: 'AX',
        label: i18n._('country.AX'),
    },
    {
        value: 'AL',
        label: i18n._('country.AL'),
    },
    {
        value: 'DZ',
        label: i18n._('country.DZ'),
    },
    {
        value: 'AS',
        label: i18n._('country.AS'),
    },
    {
        value: 'AD',
        label: i18n._('country.AD'),
    },

    {
        value: 'AO',
        label: i18n._('country.AO'),
    },
    {
        value: 'AI',
        label: i18n._('country.AI'),
    },
    {
        value: 'AQ',
        label: i18n._('country.AQ'),
    },
    {
        value: 'AG',
        label: i18n._('country.AG'),
    },
    {
        value: 'AR',
        label: i18n._('country.AR'),
    },
    {
        value: 'AM',
        label: i18n._('country.AM'),
    },
    {
        value: 'AW',
        label: i18n._('country.AW'),
    },
    {
        value: 'AU',
        label: i18n._('country.AU'),
    },
    {
        value: 'AT',
        label: i18n._('country.AT'),
    },
    {
        value: 'AZ',
        label: i18n._('country.AZ'),
    },
    {
        value: 'BS',
        label: i18n._('country.BS'),
    },
    {
        value: 'BH',
        label: i18n._('country.BH'),
    },
    {
        value: 'BD',
        label: i18n._('country.BD'),
    },
    {
        value: 'BB',
        label: i18n._('country.BB'),
    },
    {
        value: 'BY',
        label: i18n._('country.BY'),
    },
    {
        value: 'BE',
        label: i18n._('country.BE'),
    },
    {
        value: 'BZ',
        label: i18n._('country.BZ'),
    },
    {
        value: 'BJ',
        label: i18n._('country.BJ'),
    },
    {
        value: 'BM',
        label: i18n._('country.BM'),
    },
    {
        value: 'BT',
        label: i18n._('country.BT'),
    },

    {
        value: 'BO',
        label: i18n._('country.BO'),
    },
    {
        value: 'BA',
        label: i18n._('country.BA'),
    },
    {
        value: 'BW',
        label: i18n._('country.BW'),
    },
    {
        value: 'BV',
        label: i18n._('country.BV'),
    },
    {
        value: 'BR',
        label: i18n._('country.BR'),
    },
    {
        value: 'IO',
        label: i18n._('country.IO'),
    },
    {
        value: 'VG',
        label: i18n._('country.VG'),
    },
    {
        value: 'BN',
        label: i18n._('country.BN'),
    },
    {
        value: 'BG',
        label: i18n._('country.BG'),
    },
    {
        value: 'BF',
        label: i18n._('country.BF'),
    },
    {
        value: 'BI',
        label: i18n._('country.BI'),
    },
    {
        value: 'KH',
        label: i18n._('country.KH'),
    },
    {
        value: 'CM',
        label: i18n._('country.CM'),
    },
    {
        value: 'CV',
        label: i18n._('country.CV'),
    },
    {
        value: 'BQ',
        label: i18n._('country.BQ'),
    },
    {
        value: 'KY',
        label: i18n._('country.KY'),
    },
    {
        value: 'CF',
        label: i18n._('country.CF'),
    },
    {
        value: 'TD',
        label: i18n._('country.TD'),
    },
    {
        value: 'CL',
        label: i18n._('country.CL'),
    },
    {
        value: 'CN',
        label: i18n._('country.CN'),
    },
    {
        value: 'CX',
        label: i18n._('country.CX'),
    },
    {
        value: 'CC',
        label: i18n._('country.CC'),
    },
    {
        value: 'CO',
        label: i18n._('country.CO'),
    },
    {
        value: 'KM',
        label: i18n._('country.KM'),
    },
    {
        value: 'CG',
        label: i18n._('country.CG'),
    },
    {
        value: 'CD',
        label: i18n._('country.CD'),
    },
    {
        value: 'CK',
        label: i18n._('country.CK'),
    },
    {
        value: 'CK',
        label: i18n._('country.CK'),
    },

    {
        value: 'CR',
        label: i18n._('country.CR'),
    },
    {
        value: 'CI',
        label: i18n._('country.CI'),
    },
    {
        value: 'HR',
        label: i18n._('country.HR'),
    },
    {
        value: 'CU',
        label: i18n._('country.CU'),
    },
    {
        value: 'CW',
        label: i18n._('country.CW'),
    },
    {
        value: 'CY',
        label: i18n._('country.CY'),
    },
    {
        value: 'CZ',
        label: i18n._('country.CZ'),
    },
    {
        value: 'DK',
        label: i18n._('country.DK'),
    },
    {
        value: 'DJ',
        label: i18n._('country.DJ'),
    },
    {
        value: 'DM',
        label: i18n._('country.DM'),
    },
    {
        value: 'DO',
        label: i18n._('country.DO'),
    },
    {
        value: 'EC',
        label: i18n._('country.EC'),
    },
    {
        value: 'EG',
        label: i18n._('country.EG'),
    },
    {
        value: 'SV',
        label: i18n._('country.SV'),
    },
    {
        value: 'GQ',
        label: i18n._('country.GQ'),
    },
    {
        value: 'ER',
        label: i18n._('country.ER'),
    },
    {
        value: 'EE',
        label: i18n._('country.EE'),
    },
    {
        value: 'SZ',
        label: i18n._('country.SZ'),
    },
    {
        value: 'ET',
        label: i18n._('country.ET'),
    },
    {
        value: 'FK',
        label: i18n._('country.FK'),
    },
    {
        value: 'FO',
        label: i18n._('country.FO'),
    },
    {
        value: 'FJ',
        label: i18n._('country.FJ'),
    },
    {
        value: 'FI',
        label: i18n._('country.FI'),
    },
    {
        value: 'FR',
        label: i18n._('country.FR'),
    },
    {
        value: 'GF',
        label: i18n._('country.GF'),
    },
    {
        value: 'PF',
        label: i18n._('country.PF'),
    },
    {
        value: 'TF',
        label: i18n._('country.TF'),
    },
    {
        value: 'GA',
        label: i18n._('country.GA'),
    },
    {
        value: 'GM',
        label: i18n._('country.GM'),
    },
    {
        value: 'GE',
        label: i18n._('country.GE'),
    },
    {
        value: 'DE',
        label: i18n._('country.DE'),
    },
    {
        value: 'GH',
        label: i18n._('country.GH'),
    },
    {
        value: 'GI',
        label: i18n._('country.GI'),
    },
    {
        value: 'GR',
        label: i18n._('country.GR'),
    },
    {
        value: 'GL',
        label: i18n._('country.GL'),
    },
    {
        value: 'GD',
        label: i18n._('country.GD'),
    },
    {
        value: 'GP',
        label: i18n._('country.GP'),
    },
    {
        value: 'GU',
        label: i18n._('country.GU'),
    },
    {
        value: 'GT',
        label: i18n._('country.GT'),
    },
    {
        value: 'GG',
        label: i18n._('country.GG'),
    },
    {
        value: 'GN',
        label: i18n._('country.GN'),
    },
    {
        value: 'GW',
        label: i18n._('country.GW'),
    },
    {
        value: 'GY',
        label: i18n._('country.GY'),
    },
    {
        value: 'HT',
        label: i18n._('country.HT'),
    },
    {
        value: 'HM',
        label: i18n._('country.HM'),
    },
    {
        value: 'HN',
        label: i18n._('country.HN'),
    },
    {
        value: 'HK',
        label: i18n._('country.HK'),
    },
    {
        value: 'HU',
        label: i18n._('country.HU'),
    },
    {
        value: 'IS',
        label: i18n._('country.IS'),
    },
    {
        value: 'IN',
        label: i18n._('country.IN'),
    },
    {
        value: 'ID',
        label: i18n._('country.ID'),
    },
    {
        value: 'IR',
        label: i18n._('country.IR'),
    },
    {
        value: 'IQ',
        label: i18n._('country.IQ'),
    },
    {
        value: 'IE',
        label: i18n._('country.IE'),
    },
    {
        value: 'IM',
        label: i18n._('country.IM'),
    },
    {
        value: 'IL',
        label: i18n._('country.IL'),
    },
    {
        value: 'IT',
        label: i18n._('country.IT'),
    },
    {
        value: 'JM',
        label: i18n._('country.JM'),
    },
    {
        value: 'JP',
        label: i18n._('country.JP'),
    },
    {
        value: 'JE',
        label: i18n._('country.JE'),
    },
    {
        value: 'JO',
        label: i18n._('country.JO'),
    },
    {
        value: 'KZ',
        label: i18n._('country.KZ'),
    },
    {
        value: 'KE',
        label: i18n._('country.KE'),
    },
    {
        value: 'KI',
        label: i18n._('country.KI'),
    },
    {
        value: 'KW',
        label: i18n._('country.KW'),
    },
    {
        value: 'KG',
        label: i18n._('country.KG'),
    },
    {
        value: 'LA',
        label: i18n._('country.LA'),
    },
    {
        value: 'LV',
        label: i18n._('country.LV'),
    },
    {
        value: 'LB',
        label: i18n._('country.LB'),
    },
    {
        value: 'LS',
        label: i18n._('country.LS'),
    },
    {
        value: 'LR',
        label: i18n._('country.LR'),
    },
    {
        value: 'LY',
        label: i18n._('country.LY'),
    },
    {
        value: 'LI',
        label: i18n._('country.LI'),
    },
    {
        value: 'LT',
        label: i18n._('country.LT'),
    },
    {
        value: 'LU',
        label: i18n._('country.LU'),
    },
    {
        value: 'MO',
        label: i18n._('country.MO'),
    },
    {
        value: 'MG',
        label: i18n._('country.MG'),
    },
    {
        value: 'MW',
        label: i18n._('country.MW'),
    },
    {
        value: 'MY',
        label: i18n._('country.MY'),
    },
    {
        value: 'MV',
        label: i18n._('country.MV'),
    },
    {
        value: 'ML',
        label: i18n._('country.ML'),
    },
    {
        value: 'MT',
        label: i18n._('country.MT'),
    },
    {
        value: 'MH',
        label: i18n._('country.MH'),
    },
    {
        value: 'MQ',
        label: i18n._('country.MQ'),
    },
    {
        value: 'MR',
        label: i18n._('country.MR'),
    },
    {
        value: 'MU',
        label: i18n._('country.MU'),
    },
    {
        value: 'YT',
        label: i18n._('country.YT'),
    },
    {
        value: 'MX',
        label: i18n._('country.MX'),
    },
    {
        value: 'FM',
        label: i18n._('country.FM'),
    },
    {
        value: 'MD',
        label: i18n._('country.MD'),
    },
    {
        value: 'MC',
        label: i18n._('country.MC'),
    },
    {
        value: 'MN',
        label: i18n._('country.MN'),
    },
    {
        value: 'ME',
        label: i18n._('country.ME'),
    },
    {
        value: 'MS',
        label: i18n._('country.MS'),
    },
    {
        value: 'MA',
        label: i18n._('country.MA'),
    },
    {
        value: 'MZ',
        label: i18n._('country.MZ'),
    },
    {
        value: 'MM',
        label: i18n._('country.MM'),
    },
    {
        value: 'NA',
        label: i18n._('country.NA'),
    },
    {
        value: 'NR',
        label: i18n._('country.NR'),
    },
    {
        value: 'NP',
        label: i18n._('country.NP'),
    },
    {
        value: 'NL',
        label: i18n._('country.NL'),
    },
    {
        value: 'NC',
        label: i18n._('country.NC'),
    },
    {
        value: 'NZ',
        label: i18n._('country.NZ'),
    },
    {
        value: 'NI',
        label: i18n._('country.NI'),
    },
    {
        value: 'NE',
        label: i18n._('country.NE'),
    },
    {
        value: 'NG',
        label: i18n._('country.NG'),
    },
    {
        value: 'NU',
        label: i18n._('country.NU'),
    },
    {
        value: 'NF',
        label: i18n._('country.NF'),
    },
    {
        value: 'KP',
        label: i18n._('country.KP'),
    },
    {
        value: 'MK',
        label: i18n._('country.MK'),
    },
    {
        value: 'MP',
        label: i18n._('country.MP'),
    },
    {
        value: 'NO',
        label: i18n._('country.NO'),
    },
    {
        value: 'OM',
        label: i18n._('country.OM'),
    },
    {
        value: 'PK',
        label: i18n._('country.PK'),
    },
    {
        value: 'PW',
        label: i18n._('country.PW'),
    },
    {
        value: 'PS',
        label: i18n._('country.PS'),
    },
    {
        value: 'PA',
        label: i18n._('country.PA'),
    },
    {
        value: 'PG',
        label: i18n._('country.PG'),
    },
    {
        value: 'PY',
        label: i18n._('country.PY'),
    },
    {
        value: 'PE',
        label: i18n._('country.PE'),
    },
    {
        value: 'PH',
        label: i18n._('country.PH'),
    },
    {
        value: 'PN',
        label: i18n._('country.PN'),
    },
    {
        value: 'PL',
        label: i18n._('country.PL'),
    },
    {
        value: 'PT',
        label: i18n._('country.PT'),
    },
    {
        value: 'PR',
        label: i18n._('country.PR'),
    },
    {
        value: 'QA',
        label: i18n._('country.QA'),
    },
    {
        value: 'RE',
        label: i18n._('country.RE'),
    },
    {
        value: 'RO',
        label: i18n._('country.RO'),
    },
    {
        value: 'RU',
        label: i18n._('country.RU'),
    },
    {
        value: 'RW',
        label: i18n._('country.RW'),
    },
    {
        value: 'WS',
        label: i18n._('country.WS'),
    },
    {
        value: 'SM',
        label: i18n._('country.SM'),
    },
    {
        value: 'ST',
        label: i18n._('country.ST'),
    },
    {
        value: 'SA',
        label: i18n._('country.SA'),
    },
    {
        value: 'SN',
        label: i18n._('country.SN'),
    },
    {
        value: 'RS',
        label: i18n._('country.RS'),
    },
    {
        value: 'SC',
        label: i18n._('country.SC'),
    },
    {
        value: 'SL',
        label: i18n._('country.SL'),
    },
    {
        value: 'SG',
        label: i18n._('country.SG'),
    },
    {
        value: 'SX',
        label: i18n._('country.SX'),
    },
    {
        value: 'SK',
        label: i18n._('country.SK'),
    },
    {
        value: 'SI',
        label: i18n._('country.SI'),
    },
    {
        value: 'SB',
        label: i18n._('country.SB'),
    },
    {
        value: 'SO',
        label: i18n._('country.SO'),
    },
    {
        value: 'ZA',
        label: i18n._('country.ZA'),
    },
    {
        value: 'GS',
        label: i18n._('country.GS'),
    },
    {
        value: 'KR',
        label: i18n._('country.KR'),
    },
    {
        value: 'SS',
        label: i18n._('country.SS'),
    },
    {
        value: 'ES',
        label: i18n._('country.ES'),
    },
    {
        value: 'LK',
        label: i18n._('country.LK'),
    },
    {
        value: 'BL',
        label: i18n._('country.BL'),
    },
    {
        value: 'SH',
        label: i18n._('country.SH'),
    },
    {
        value: 'KN',
        label: i18n._('country.KN'),
    },
    {
        value: 'LC',
        label: i18n._('country.LC'),
    },
    {
        value: 'MF',
        label: i18n._('country.MF'),
    },
    {
        value: 'PM',
        label: i18n._('country.PM'),
    },
    {
        value: 'VC',
        label: i18n._('country.VC'),
    },
    {
        value: 'SD',
        label: i18n._('country.SD'),
    },
    {
        value: 'SR',
        label: i18n._('country.SR'),
    },
    {
        value: 'SJ',
        label: i18n._('country.SJ'),
    },
    {
        value: 'SE',
        label: i18n._('country.SE'),
    },
    {
        value: 'CH',
        label: i18n._('country.CH'),
    },
    {
        value: 'SY',
        label: i18n._('country.SY'),
    },
    {
        value: 'TW',
        label: i18n._('country.TW'),
    },
    {
        value: 'TJ',
        label: i18n._('country.TJ'),
    },
    {
        value: 'TZ',
        label: i18n._('country.TZ'),
    },
    {
        value: 'TH',
        label: i18n._('country.TH'),
    },
    {
        value: 'TL',
        label: i18n._('country.TL'),
    },
    {
        value: 'TG',
        label: i18n._('country.TG'),
    },
    {
        value: 'TK',
        label: i18n._('country.TK'),
    },
    {
        value: 'TO',
        label: i18n._('country.TO'),
    },
    {
        value: 'TT',
        label: i18n._('country.TT'),
    },
    {
        value: 'TN',
        label: i18n._('country.TN'),
    },
    {
        value: 'TR',
        label: i18n._('country.TR'),
    },
    {
        value: 'TM',
        label: i18n._('country.TM'),
    },
    {
        value: 'TC',
        label: i18n._('country.TC'),
    },
    {
        value: 'TV',
        label: i18n._('country.TV'),
    },
    {
        value: 'UM',
        label: i18n._('country.UM'),
    },
    {
        value: 'VI',
        label: i18n._('country.VI'),
    },
    {
        value: 'UG',
        label: i18n._('country.UG'),
    },
    {
        value: 'UA',
        label: i18n._('country.UA'),
    },
    {
        value: 'AE',
        label: i18n._('country.AE'),
    },
    {
        value: 'GB',
        label: i18n._('country.GB'),
    },
    {
        value: 'UY',
        label: i18n._('country.UY'),
    },
    {
        value: 'UZ',
        label: i18n._('country.UZ'),
    },
    {
        value: 'VU',
        label: i18n._('country.VU'),
    },
    {
        value: 'VA',
        label: i18n._('country.VA'),
    },
    {
        value: 'VE',
        label: i18n._('country.VE'),
    },
    {
        value: 'VN',
        label: i18n._('country.VN'),
    },
    {
        value: 'WF',
        label: i18n._('country.WF'),
    },
    {
        value: 'EH',
        label: i18n._('country.EH'),
    },
    {
        value: 'YE',
        label: i18n._('country.YE'),
    },
    {
        value: 'ZM',
        label: i18n._('country.ZM'),
    },
    {
        value: 'ZW',
        label: i18n._('country.ZW'),
    },
];

export const Provinces = [
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NS',
    'NT',
    'NU',
    'ON',
    'PE',
    'QC',
    'SK',
    'YT',
] as const;

export type Province = (typeof Provinces)[keyof typeof Provinces];

export const PROVINCES_OPTIONS = (i18n: I18n): SelectOptions<Province> =>
    sortByKey(
        Provinces.map((province) => ({
            value: province,
            label: i18n._(`provinces.${province}`),
        })),
        'label'
    );

export const US_STATES_OPTIONS = (i18n: I18n): SelectOptions => [
    { value: 'AL', label: i18n._('states.AL') },
    { value: 'AK', label: i18n._('states.AK') },
    { value: 'AZ', label: i18n._('states.AZ') },
    { value: 'AR', label: i18n._('states.AR') },
    { value: 'CA', label: i18n._('states.CA') },
    { value: 'CO', label: i18n._('states.CO') },
    { value: 'CT', label: i18n._('states.CT') },
    { value: 'DE', label: i18n._('states.DE') },
    { value: 'DC', label: i18n._('states.DC') },
    { value: 'FL', label: i18n._('states.FL') },
    { value: 'GA', label: i18n._('states.GA') },
    { value: 'HI', label: i18n._('states.HI') },
    { value: 'ID', label: i18n._('states.ID') },
    { value: 'IL', label: i18n._('states.IL') },
    { value: 'IN', label: i18n._('states.IN') },
    { value: 'IA', label: i18n._('states.IA') },
    { value: 'KS', label: i18n._('states.KS') },
    { value: 'KY', label: i18n._('states.KY') },
    { value: 'LA', label: i18n._('states.LA') },
    { value: 'ME', label: i18n._('states.ME') },
    { value: 'MD', label: i18n._('states.MD') },
    { value: 'MA', label: i18n._('states.MA') },
    { value: 'MI', label: i18n._('states.MI') },
    { value: 'MN', label: i18n._('states.MN') },
    { value: 'MS', label: i18n._('states.MS') },
    { value: 'MO', label: i18n._('states.MO') },
    { value: 'MT', label: i18n._('states.MT') },
    { value: 'NE', label: i18n._('states.NE') },
    { value: 'NV', label: i18n._('states.NV') },
    { value: 'NH', label: i18n._('states.NH') },
    { value: 'NJ', label: i18n._('states.NJ') },
    { value: 'NM', label: i18n._('states.NM') },
    { value: 'NY', label: i18n._('states.NY') },
    { value: 'NC', label: i18n._('states.NC') },
    { value: 'ND', label: i18n._('states.ND') },
    { value: 'OH', label: i18n._('states.OH') },
    { value: 'OK', label: i18n._('states.OK') },
    { value: 'OR', label: i18n._('states.OR') },
    { value: 'PA', label: i18n._('states.PA') },
    { value: 'RI', label: i18n._('states.RI') },
    { value: 'SC', label: i18n._('states.SC') },
    { value: 'SD', label: i18n._('states.SD') },
    { value: 'TN', label: i18n._('states.TN') },
    { value: 'TX', label: i18n._('states.TX') },
    { value: 'UT', label: i18n._('states.UT') },
    { value: 'VT', label: i18n._('states.VT') },
    { value: 'VA', label: i18n._('states.VA') },
    { value: 'WA', label: i18n._('states.WA') },
    { value: 'WV', label: i18n._('states.WV') },
    { value: 'WI', label: i18n._('states.WI') },
    { value: 'WY', label: i18n._('states.WY') },
];
